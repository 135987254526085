<template>
  <div class="mx-5">
    <div class="d_flex align-center justify-end">
      <div>
        <strong>{{ inventoryNumber }}</strong> serial tồn kho
      </div>
    </div>
    <!-- Start: Search area -->
    <div class="d_flex flex-column align-center justify-center">
      <div class="text-h6 font-weight-medium text-center mb-3">
        Tìm kiếm Serials
      </div>
      <tp-search-option
        :options="searchOptions"
        :value="searchKey"
        @search="updateSearchKey($event)"
      ></tp-search-option>
    </div>
    <!-- Start: Search area -->
    <!-- Start: Search results -->
    <template v-if="searchedSerials && searchedSerials.length > 0">
      <!-- Start: Found serials -->
      <div class="d_flex flex-wrap align-center mt-8">
        <div class="font-weight-medium mr-3">Serials tìm được:</div>
        <v-chip
          class="serial-item mr-3 my-1"
          :class="{ 'font-weight-medium': checkActiveSerial(item.id) }"
          :color="checkActiveSerial(item.id) ? 'cyan lighten-4' : 'white'"
          v-for="(item, index) in searchedSerials"
          :key="index"
          label
          @click.prevent="changeActiveSerial(item)"
        >
          {{ item.serial_number }}
        </v-chip>
      </div>
      <!-- End: Found serials -->
      <!-- Start: Serial info -->
      <template v-if="Object.keys(selectedSerial).length">
        <v-card class="mt-5" flat>
          <v-card-title class="align-center px-5">
            <div class="font-weight-bold">
              #{{ selectedSerial.serial_number }}
            </div>
            <!-- Start: Serial status -->
            <template>
              <v-chip
                class="font-weight-medium px-2 ml-2"
                :color="getSerialStatus(selectedSerial.status).color"
                small
                outlined
              >
                {{ getSerialStatus(selectedSerial.status).text }}
              </v-chip>
              <v-chip
                v-if="selectedSerial.order_status"
                class="font-weight-bold ml-2"
                color="light-blue"
                outlined
                small
              >
                Bị đặt
              </v-chip>
              <v-chip
                v-if="selectedSerial.exchange_status"
                class="font-weight-bold ml-2"
                color="orange"
                outlined
                small
              >
                Đang chuyển
              </v-chip>
            </template>
            <!-- End: Serial status -->
            <v-spacer></v-spacer>

            <v-btn
              class="rounded-lg mr-4"
              depressed
              color="primary"
              @click="openModalUpdateSerial"
            >
              Chỉnh sửa
            </v-btn>
            <v-btn
              class="grey--text text--darken-3 rounded-lg"
              color="grey lighten-3"
              depressed
              @click="deleteSerial"
            >
              Xóa
            </v-btn>
          </v-card-title>
          <!-- Start: Serial info -->
          <serial-info
            :productInfo="selectedSerial.product_info"
            :guaranteeInfo="selectedSerial.guarantee_info"
            :boughtBy="selectedSerial.bought_by"
            :createdPriceAudits="selectedSerial.created_price_audits"
          ></serial-info>
          <!-- End: Serial info -->
          <!-- Start: Created price audits -->
          <v-container
            v-if="selectedSerial.created_price_audits.length"
            class="pt-0 pb-5  px-5"
            fluid
          >
            <div class="text-h6 font-weight-bold">
              Thay đổi giá ({{ selectedSerial.created_price_audits.length }})
            </div>

            <div
              v-for="(item, index) in selectedSerial.created_price_audits"
              :key="index"
            >
              <div class="px-2 py-3 text-body-1">{{ item.label }}</div>
              <v-divider
                v-if="index !== selectedSerial.created_price_audits.length - 1"
              ></v-divider>
            </div>
          </v-container>
          <!-- End: Created price audits -->
          <!-- Start: Serial history -->
          <serial-history
            :histories="selectedSerial.histories"
          ></serial-history>
          <!-- End: Serial history -->
          <!-- Start: Modal update serial -->
          <modal-update-serial></modal-update-serial>
          <!-- End: Modal update serial -->
        </v-card>
      </template>
      <!-- End: Serial info -->
    </template>
    <!-- End: Search results -->
    <!-- Start: Empty -->
    <template v-else>
      <v-card class="mt-8 pb-3" flat>
        <v-card-title
          class="text-h6 font-weight-light grey--text text--darken-2 justify-center text-center pb-2"
        >
          Không có kết quả nào.
        </v-card-title>
        <v-card-text class="text-body-1 text-center">
          <v-icon color="grey lighten-1" size="20px"
            >mdi-information-outline</v-icon
          >
          Tra cứu thông tin serial bằng cách lựa chọn tìm kiếm theo số serial
          hoặc mã SKU.
        </v-card-text>
      </v-card>
    </template>
    <!-- End: Empty -->
  </div>
</template>

<script>
import { getSerialStatusByValue } from "../../utils/common";
import ModalUpdateSerial from "./components/modal/ModalUpdateSerial";
import SerialHistory from "./components/SerialHistory";
import SerialInfo from "./components/SerialInfo";

export default {
  components: {
    ModalUpdateSerial,
    SerialHistory,
    SerialInfo
  },
  data() {
    return {
      searchKey: null,
      searchOptions: [
        { type: 1, label: "Tìm theo serial" },
        { type: 2, label: "Tìm theo SKU" }
      ]
    };
  },
  computed: {
    inventoryNumber() {
      return this.$store.getters["SERIAL/inventoryNumber"];
    },
    searchedSerials() {
      return this.$store.getters["SERIAL/searchedSerials"];
    },
    selectedSerial() {
      return this.$store.getters["SERIAL/selectedSerial"];
    },
    serialRequestStatus() {
      return this.$store.getters["SERIAL/statusRequest"];
    }
  },
  created() {
    this.$store.dispatch("SERIAL/getInventoryNumber");
  },
  methods: {
    changeActiveSerial(item) {
      this.$store.dispatch("SERIAL/getSelectedSerial", { serial_id: item.id });
    },

    checkActiveSerial(id) {
      return id === this.selectedSerial.id;
    },

    async deleteSerial() {
      // Confirm
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Xóa serial <strong>${this.selectedSerial.serial_number}</strong></span>`,
          message:
            "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
          confirmBtn: {
            color: "red accent-2",
            text: "Xóa",
            onClickHandler: async () => {
              await this.$store.dispatch(
                "SERIAL/deleteSerial",
                this.selectedSerial.id
              );
              if (this.serialRequestStatus.value === "success-delete-serial") {
                this.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: "Đã xóa serial"
                  }
                });
              }
            }
          }
        }
      });
    },

    getSerialStatus(value) {
      return getSerialStatusByValue(value);
    },

    async openModalUpdateSerial() {
      await this.$store.dispatch("SERIAL/getForUpdateSerial", {
        serial_id: this.selectedSerial.id
      });
      this.$modal.show({
        name: "modal-serials-update-serial"
      });
    },

    updateSearchKey(val) {
      if (val) {
        this.$store.dispatch("SERIAL/getSearchedSerials", {
          type: val.option,
          search: val.searchKey
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.serial-item {
  cursor: pointer;
}
</style>
